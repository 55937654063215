<template>
  <div>
    <el-table v-if="modrForm.modr_mtrl_list" ref="multiTable" :data="modrForm.modr_mtrl_list" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index+1 }}
        </template>
      </el-table-column>
      <el-table-column label="物料编号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.mtrl_no'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="modrForm.modr_mtrl_list[scope.$index].mtrl_no" maxlength="30" show-word-limit placeholder="暂无物料编号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料名称">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.mtrl_name'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="modrForm.modr_mtrl_list[scope.$index].mtrl_name" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料类型">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.mtrl_type'" :rules="[{required:true}]">
            <el-select :disabled="true" v-model="modrForm.modr_mtrl_list[scope.$index].mtrl_type" placeholder="请选择物料类型">
              <el-option
                  v-for="item in mtrlTypeGroupOpt"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="规格">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.mtrl_spec'">
            <el-input :disabled="true" v-model="modrForm.modr_mtrl_list[scope.$index].mtrl_spec" maxlength="50" show-word-limit placeholder="暂无规格"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="潘通色号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.mtrl_color'">
            <el-input :disabled="true" v-model="modrForm.modr_mtrl_list[scope.$index].mtrl_color" maxlength="10" show-word-limit placeholder="暂无潘通色号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="克重">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.mtrl_weight'">
            <el-input :disabled="true" v-model="modrForm.modr_mtrl_list[scope.$index].mtrl_weight" show-word-limit placeholder="暂无克重"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="门幅">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.mtrl_width'">
            <el-input :disabled="true" v-model="modrForm.modr_mtrl_list[scope.$index].mtrl_width" show-word-limit placeholder="暂无门幅"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单位">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.mtrl_unit'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="modrForm.modr_mtrl_list[scope.$index].mtrl_unit" show-word-limit placeholder="暂无单位"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot="header">
          <span class="vd_title">数量</span> 
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.modr_mtrl_num'" :rules="[{required:true}]">
            <el-input @change="totalAmount(scope,1)" @input="modrForm.modr_mtrl_list[scope.$index].modr_mtrl_num = helper.keepTNum1(modrForm.modr_mtrl_list[scope.$index].modr_mtrl_num)" maxlength="9" show-word-limit v-model="modrForm.modr_mtrl_list[scope.$index].modr_mtrl_num" placeholder="暂无数量"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot="header">
          <span class="vd_title">价格(元)</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.modr_mtrl_price'" :rules="[{required:true}]">
            <el-input @change="totalAmount(scope,2)" @input="modrForm.modr_mtrl_list[scope.$index].modr_mtrl_price = helper.keepTNum1(modrForm.modr_mtrl_list[scope.$index].modr_mtrl_price)" maxlength="9" v-model="modrForm.modr_mtrl_list[scope.$index].modr_mtrl_price" show-word-limit placeholder="暂无价格"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="订单产品物料" width="200" v-if="flag">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_mtrl_list.'+scope.$index+'.podr_prod_str'">
            <el-input maxlength="9" show-word-limit v-model="modrForm.modr_mtrl_list[scope.$index].podr_prod_str" disabled placeholder="暂无订单产品物料">
              <template slot="append">
                  <el-link type="primary" class="vg_cursor" :disabled="isShow" @click="cleanSechar(scope)">选择</el-link>
                </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="订单产品物料"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="cancelDia">
      <el-select size="small" clearable v-model="changeForm.id" placeholder="请选择订单产品物料">
        <el-option
            v-for="item in podtList"
            :key="item.podr_prod_mtrl_id"
            :label="item.podtValue"
            :value="item.podr_prod_mtrl_id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelDia">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmDia">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import options from "@/views/component/common/options";
import {podtAPI} from "@api/modules/podt";

export default {
  name: "ModrAddMtrl",
  props:{
    modrForm:{
      type: Object,
      required:true,
    },
    isShow:{
      type:Boolean,
      required:true
    },
    flag:{
      type:Boolean,
      required:true
    }
  },
  data(){
    return {
      selectionsList:[],
      mtrlTypeGroupOpt:[
        {value:0,label:'原面料'},
        {value:1,label:'辅料'},
        {value:2,label:'包材'},
        {value:3,label:'加工面料'},
        {value:4,label:'裁片'},
        {value:5,label:'在制品'},
      ],
      podtList:[],
      dialogVisible: false,
      changeForm:{
        value:'',
        id:null
      },
      changeNum:null
    }
  },
  created(){
  },
  methods:{
    // 获取订单产品物料
    getPodtList(scope){
      get(podtAPI.getPodtsByMtrlId,{mtrl_id:scope.row.mtrl_id})
      .then(res=>{
        if(res.data.code === 0) {
          this.podtList = res.data.data.list
          for(let i=0;i<this.podtList.length;i++){
            this.podtList[i].podtValue = this.podtList[i].podr_no + '  ' + this.podtList[i].prod_no 
          }
          if(this.podtList.length>0){
            this.dialogVisible = true
            this.changeForm.id = this.modrForm.modr_mtrl_list[this.changeNum].podr_prod_mtrl_id
          }else{
            this.$message.warning('该物料暂未绑定产品工单')
          }
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 选择
    cleanSechar(scope){
      this.changeNum = scope.$index
      this.getPodtList(scope)
    },
    // 取消选择
    cancelDia(){
      this.dialogVisible = false
      this.changeForm={
        value:'',
        id:null
      }
    },
    // 确定选择
    confirmDia(){
      let temp = null;
      temp = this.podtList.filter(item=>{
        return item.podr_prod_mtrl_id === this.changeForm.id
      })
      // this.modrForm.modr_mtrl_list[this.changeNum].podr_prod_str = temp[0].podtValue
      // this.modrForm.modr_mtrl_list[this.changeNum].podr_prod_mtrl_id = temp[0].podr_prod_mtrl_id
      this.$emit("confirmDia",temp,this.changeNum)
      this.dialogVisible = false
    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
      this.multiSelection = null
    },
    // getKey(row){
    //   return row.id
    // },
    //物料信息选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
      this.$emit("handleSelectionChange",this.selectionsList)
    },
    // 计算总金额
    totalAmount(scope,type){
      if(type === 1){
        scope.row.modr_mtrl_num = this.helper.calcPrice(scope.row.modr_mtrl_num,4,10000)
      }else if(type === 2){
        scope.row.modr_mtrl_price = this.helper.calcPrice(scope.row.modr_mtrl_price,4,10000)
      }
      let temp = 0;
      if(this.modrForm.modr_mtrl_list.length>0){
        for(let i=0;i<this.modrForm.modr_mtrl_list.length;i++){
          if(this.modrForm.modr_mtrl_list[i].modr_mtrl_num!==undefined && this.modrForm.modr_mtrl_list[i].modr_mtrl_price!==undefined){
            let num1 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_num)*100000
            let num2 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
      }  
      if(this.modrForm.extra_price){
        temp = temp + Number(this.modrForm.extra_price)
      }
      this.modrForm.modr_total = this.helper.haveFour(Number(temp));
    }
  }
}
</script>

<style scoped lang="scss">
.vd_title{
  color: $color-deep-red;
}
</style>