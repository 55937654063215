<template>
  <div class="vg_mtb_16">
    <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrlsNow()">
      <el-row>
        <el-col :md="7">
          <el-form-item label="物料编号：">
            <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="7">
          <el-form-item label="物料名称：">
            <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="10">
          <el-form-item label="潘通色号：">
            <el-input size="small" v-model.trim="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()" class="vg_ml_16">查询</el-button>
            <el-button size="small" type="info" icon="el-icon-refresh" @click="getMtrlsNowO()" class="vg_ml_16">刷新</el-button>
          </el-form-item>
        </el-col>
        <!-- <el-col :md="8">
          <el-form-item label="物料类型：">
            <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable >
              <el-option
                  v-for="item in mtrlType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
      </el-row>
    </el-form>
    <el-row class="vd_mar10">
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      </el-col>
      <el-col :md="8" class="disRight">
      </el-col>
    </el-row> 
    <el-row class="vd_mar15">
      <!-- <div><i class="el-icon-info vg_mr_8"></i>{{title}}</div> -->
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" :row-key="getRowKey" border v-loading="loadingFlag">
          <el-table-column type="selection" width="48"  align="center" :reserve-selection="true"/>
         <el-table-column label="物料编号" prop="mtrl_no"/>
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <el-image 
                  v-if="scope.row.imge_url"
                  class="vd_elimg"
                  :src="formatPic(scope,'s')"  
                  fit="fill">
                </el-image>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name" show-overflow-tooltip />
            <!-- <el-table-column label="供应商" prop="supp_name"  show-overflow-tooltip/> -->
            <el-table-column label="物料规格" prop="mtrl_spec"  show-overflow-tooltip >
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_spec">
                  {{ scope.row.mtrl_spec}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料类型" >
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_type>=0">
                  {{scope.row.mtrl_type | formatLeavType}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料成分" prop="mtrl_comp" >
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_comp">
                  {{ scope.row.mtrl_comp}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="潘通色号" prop="mtrl_color">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{'vd_div':scope.row.colr_html}" :style="{'background-color': scope.row.colr_html}"></div>
                    <span>{{ scope.row.mtrl_color}}</span> 
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料门幅" prop="mtrl_width">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_width">
                  {{ scope.row.mtrl_width | formaUnitM}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料毛高" prop="mtrl_thick">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_thick">
                  {{ scope.row.mtrl_thick | formaUnitH}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get} from "@api/request";
import {mtrlAPI} from "@api/modules/mtrl"
import pubPagination from "@/components/common/pubPagination";
// import selectStatus from "@/views/component/selectStatus";
import options from "@/views/component/common/options";
import helper from "@assets/js/helper.js"
export default {
  name: "ModrAddEditIn",
  components:{
    // selectStatus,
    pubPagination,
  },
  data() {
    return {
      tableData:[],
      searchForm:{
        mtrl_no:null,
        mtrl_name:null,
        mtrl_type:null,
        mtrl_color:null,
      },
      totalPage:0,
      btn:{},
      loading: true,
      multiSelection: [],
      currentPage:1,
      loadingFlag:true,
      mtrlType:[
        {id:0,label:'原面料'},
        {id:1,label:'辅料'},
        {id:2,label:'包材'},
      ],
      imgUpdatatime:parseInt(Math.random()*100000000),
      title:'提示：相同数据仅限导入一条',
    }
  },
  created() {
    this.initData()
    this.mtrlTypeGroupCrft = options.mtrlUnit;
  },
  filters:{
    // 物料类型
    formatLeavType(row){
      if(row === 0){
        return '原面料'
      } else if(row === 1){
        return '辅料'
      } else if(row === 2){
        return '包材'
      }else if(row === 3){
        return '加工面料'
      }else if(row === 4){
        return '裁片'
      }else if(row === 5){
        return '在制品'
      }
    },
    formaUnitM(row){
      return helper.haveFour(row)+'米'
    },
    formaUnitH(row){
      return helper.reservedDigits(row)+'毫米'
    },
  },
  methods:{
    initData(){
      this.loadingFlag = true
      this.getMtrlsList()
    },
    // 获取物料信息
    getMtrlsList(){
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(mtrlAPI.getMtrlsInclusive,{
        mtrl_no:this.searchForm.mtrl_no,
        mtrl_name:this.searchForm.mtrl_name,
        mtrl_color:this.searchForm.mtrl_color,
        mtrl_type:0,
        status: 2,
        page_no: this.currentPage,
      })
      .then(res=>{
        if(res.data.code === 0){
          this.loading = false;
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadingFlag = false
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
      })
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 图片类型
    formatPic(scope,type){
      let updateTime = this.imgUpdatatime
      return this.helper.picUrl(scope.row.imge_url,type,updateTime,scope.row.imge_source)
    },
    // 查询方法
    getMtrlsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 刷新方法
    getMtrlsNowO(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.searchForm={
        mtrl_no:null,
        mtrl_name:null,
        mtrl_color:null
      },
      this.initData()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 确认选择
    confirmIn(){
      if(this.multiSelection.length>0){
        this.$emit("confirmIn",this.multiSelection)
      }else{
        this.$message.warning("至少选择一条数据")
      }
    },
    // 取消选择
    cancel(){
      this.$emit("cancelModrIn")
      this.clear()
    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
      // this.multiSelection = null;
    },
    // 指定一个key标识这一行的数据
    getRowKey (row) {
      return row.mtrl_id;
    },
    // 多选
    handleSelectionChange(val){
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.initData()
    },
    // 物料类型
    formatLeavType(row){
      if(row.mtrl_type === 1){
        return '面料'
      } else if(row.mtrl_type === 2){
        return '非面料'
      }
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mar15{
  margin: 15px 0;
}
.vd_mar10{
  margin: 10px 0;
}
.vd_elimg{
  width: 50px;;height: 50px;
}
.vd_div{
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis{
  display: flex;
}
</style>